import makeStyles from '@material-ui/core/styles/makeStyles';
import { ORANGE } from '@theme_color';

const useStyles = makeStyles(() => ({
    otpContainer: {
        border: '1px solid #E8EDF1',
        borderRadius: '8px',
    },
    mt_20: {
        marginTop: 20,
    },
    otpBtn: {
        maxWidth: '100%',
        height: 50,
        background: ORANGE,

        '&:hover': {
            background: '#dc6e1a',
        },
        '& span': {
            fontSize: '20px',
            fontWeight: '500',
        },
    },
    otpInputContainer: {
        textAlign: 'center',
        '& input, fieldset': {
            borderColor: '#D5EAFB',
            width: '100%',
            padding: '10px 15px !important',
            fontWeight: '600',
            letterSpacing: 12,
            marginRight: -12,
            textAlign: 'center',
        },
        '& .Mui-focused input, .Mui-focused fieldset': {
            borderColor: '#06AEC9 !important',
        },
        '& .MuiOutlinedInput-root:hover fieldset': {
            borderColor: '#06AEC9 !important',
        },
    },
    otpInputContainerButton: {
        textAlign: 'center',
        marginTop: 10,
    },
    otpTitle: {
        color: '#414048',
        fontWeight: 'bold',
        letterSpacing: 0,
    },
    otpBtnLable: {
        fontSize: '14px !important',
        fontWeight: 'normal !important',
    },
    otpDescription: {
        color: '#7B9AAF',
        fontWeight: '400',
        letterSpacing: 0,
    },
}));

export default useStyles;
