/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import React from 'react';
import useStyles from '@core_modules/checkout/pages/default/components/OtpButtonTimer/style';
import OtpButtonTimerView from '@core_modules/checkout/pages/default/components/OtpButtonTimer/view';

const OtpButtonTimer = ({
    t,
    onClickResend,
    timeConfig,
}) => {
    const styles = useStyles();
    const timerInvtervalRef = React.useRef(null);
    const mount = React.useRef(false);
    const getConfigTimeOtp = (Number(timeConfig) * 60) ?? 900;
    const [loading, setLoading] = React.useState(false);
    const [timerCount, setTimer] = React.useState(getConfigTimeOtp);
    const isTimerNotRunning = timerCount <= 0 && !loading;

    React.useEffect(() => {
        mount.current = true;
        if (mount.current && !Number.isNaN(timerCount)) {
            timerInvtervalRef.current = setInterval(() => {
                setTimer((lastTimerCount) => {
                    lastTimerCount <= 1 && clearInterval(timerInvtervalRef.current);
                    return lastTimerCount - 1;
                });
            }, 1000);
        }
        return () => {
            clearInterval(timerInvtervalRef.current);
            mount.current = false;
        };
    }, []);

    const onResendOtp = async () => {
        try {
            setTimer(getConfigTimeOtp);
            timerInvtervalRef.current = setInterval(() => {
                setTimer((lastTimerCount) => {
                    lastTimerCount <= 1 && clearInterval(timerInvtervalRef.current);
                    return lastTimerCount - 1;
                });
            }, 1000);

            setLoading(true);
            await onClickResend();
            setLoading(false);
        } catch (err) {
            console.log('[err] on resend otp', err);
        }
    };

    const viewProps = {
        t,
        loading,
        styles,
        isTimerNotRunning,
        timerCount,
        onResendOtp,
    };

    return (
        <OtpButtonTimerView {...viewProps} />
    );
};

export default OtpButtonTimer;
