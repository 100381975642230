import { setLocalStorage, getLocalStorage } from '@helper_localstorage';

export const onGenerateId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const onGenerateVisitorId = () => {
    let visitor_id = getLocalStorage('visitor_id');
    if (visitor_id === null) {
        visitor_id = onGenerateId(24);
        setLocalStorage('visitor_id', visitor_id);
        return visitor_id;
    }
    return visitor_id;
};

export const getTimer = (sec) => {
    const secValue = Number(sec);
    const h = Math.floor(secValue / 3600);
    const m = Math.floor((secValue % 3600) / 60);
    const s = Math.floor((secValue % 3600) % 60);

    const mString = m < 10 ? `0${m}` : m;
    const sString = s < 10 ? `0${s}` : s;

    const hDisplay = h > 0 ? `${h} : ` : '';
    const mDisplay = m > 0 ? `${mString} : ` : '00:';
    const sDisplay = s > 0 ? `${sString}` : '00';
    return hDisplay + mDisplay + sDisplay;
};
