import Button from '@material-ui/core/Button';
import { getTimer } from '@helpers/helpers';

const OtpButtonTimerView = ({
    t,
    loading,
    styles,
    isTimerNotRunning,
    onResendOtp,
    timerCount,
}) => (
    <Button
        loading={loading}
        disabled={!isTimerNotRunning || loading}
        variant="contained"
        className={isTimerNotRunning ? styles.buttonOtp : styles.buttonOtpDisabled}
        onClick={isTimerNotRunning ? onResendOtp : () => {}}
    >
        {isTimerNotRunning ? t('checkout:resend') : getTimer(timerCount)}
    </Button>
);

export default OtpButtonTimerView;
