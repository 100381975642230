import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    buttonOtp: {
        background: '#F58732',
        borderColor: '#F58732',
        color: '#FFFFFF',
        boxShadow: 'none',
        marginLeft: 5,
        '&:hover': {
            background: '#F58732',
            borderColor: '#F58732',
            color: '#FFFFFF',
            boxShadow: 'none',
        },
    },
    buttonOtpDisabled: {
        marginLeft: 5,
        '& > .MuiButton-label': {
            color: '#537da0 !important',
            fontWeight: 'bolder',
        },
    },
}));

export default useStyles;
